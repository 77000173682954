import React from 'react';
import Seo from '../components/molecules/Seo';
import ConnectedPostList from '../components/organisms/PostList';
import { graphql } from 'gatsby';
import PageHolder from '../components/atoms/PageHolder';

const IndexPage = ({ data }) => (
    <>
        <Seo title="Henry Wolff" />
        <PageHolder>
            <ConnectedPostList posts={data.allContentfulProjectPreviewAsset.nodes} />
        </PageHolder>
    </>
);

export default IndexPage;

export const indexQuery = graphql`
    query indexQuery {
        allContentfulProjectPreviewAsset {
          nodes {
            id
            asset {
              fluid(maxWidth: 4000) {
                aspectRatio
                ...GatsbyContentfulFluid_noBase64
              }
              file {
                contentType
                url
              }
            }
            videoPosterImage {
              fixed(width: 1200, quality: 90) {
                src
              }
            }
            project {
              slug
            }
          }
        }
    }
`;
