import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import PostHolderFloat from './PostHolderFloat';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import 'intersection-observer';
import {useInView} from 'react-intersection-observer';
import {Link} from "gatsby";

const ImageHolder = styled.div`
  cursor: pointer;
  filter: ${props => props.greyscale ? 'grayscale(1)' : 'grayscale(0)'};
  transition: filter 0.5s;

  video {
    width: ${1.7778 * 70}vh; //16:9
    height: auto;
    max-width: 100%;
    margin: 0;
  }
`;

function PostHolder({post, neat, id, setHover, greyscale, hovered, lightboxOpen}) {

  // textInput must be declared here so the ref can refer to it
  const vidRef = useRef(null);

  const {ref, inView} = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (vidRef.current) {
      if (!lightboxOpen && inView) {
        vidRef.current.play();
      } else {
        vidRef.current.pause();
      }
    }
  }, [lightboxOpen, vidRef, inView]);

  return (
    <PostHolderFloat neat={neat} greyscale={greyscale} hovered={hovered}>
      <ImageHolder
        ref={ref}
        greyscale={greyscale}
        onMouseEnter={() => setHover(id)}
        onMouseLeave={() => setHover(null)}>
        <Link to={`/${post.project.slug}?heroasset=${post.id}`}>
          {post.asset.file && post.asset.file.contentType.includes('video') &&
          <video ref={vidRef}
                 muted
                 loop
                 playsInline
                 controls={false}
                 poster={post.videoPosterImage && post.videoPosterImage.fixed.src}>
            <source src={post.asset.file.url} type={post.asset.file.contentType} />
            Your browser does not support the video tag.
          </video>}

          {post.asset.fluid && <GatsbyImage
            style={{
              height: '100%',
              width: `${post.asset.fluid.aspectRatio * 70}vh`,
              maxWidth: '100%',
            }}
            fluid={post.asset.fluid} />}
        </Link>
      </ImageHolder>
    </PostHolderFloat>
  )
}

PostHolder.propTypes = {
  neat: PropTypes.bool.isRequired,
  greyscale: PropTypes.bool,
  hovered: PropTypes.bool.isRequired,
  post: PropTypes.object.isRequired,
  setHover: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default PostHolder;
