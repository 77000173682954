import React, { useState, useEffect } from 'react';
import PostHolder from '../molecules/PostHolder';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PostListHolder from '../molecules/PostListHolder';
import { shuffleArray } from '../../utils/helpers';


function PostList( { neat, posts } ) {
  const [ shuffledPosts, setShuffledPosts ] = useState( null );
  const [ hover, setHover ] = useState( null );

  useEffect( () => {
    setShuffledPosts( shuffleArray( posts ) );
  }, [ posts ] );

  if ( !shuffledPosts ) return null;

  return (
    <>
      <PostListHolder neat={neat}>
        {shuffledPosts.map( ( post, i ) =>
          <PostHolder
            key={i}
            id={i}
            setHover={( i ) => setHover( i )}
            greyscale={hover !== null && hover !== i}
            post={post}
            hovered={hover === i}
            neat={neat}/>
        )}
      </PostListHolder>
    </>
  )
}

PostList.propTypes = {
  neat: PropTypes.bool.isRequired,
  posts: PropTypes.array.isRequired,
};

const mapStateToProps = ( { neat } ) => {
  return { neat }
};

const ConnectedPostList = connect( mapStateToProps )( PostList );
export default ConnectedPostList;
