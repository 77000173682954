import React from 'react';
import styled from 'styled-components';

const List = styled.div`
  width: 100%;
  padding: 1rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 20vh 0;
  }
`;

const Inner = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
`;

function PostListHolder( { children } ) {

  return (
    <List>
      <Inner>
        {children}
      </Inner>
    </List>
  )
}

export default PostListHolder;
