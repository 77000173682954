import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { randomInteger } from '../../utils/helpers';

const Holder = styled.li`
  margin: 0 2rem 2rem 2rem;
  display: flex;
  justify-content: center;
  transition: margin 1s ease-in-out;
  position: relative;
  z-index: ${props => props.greyscale ? -1 : 0};
  @media(${props => props.theme.breakpoints.md}) {
    margin: 2rem 5rem;
  }
`;

const ImageHolder = styled.div.attrs(props => ({
  style: {
    transform: props.transform && !props.neat ? `translate(${props.transform[ 0 ]}px, ${props.transform[ 1 ]}px)` : 'none',
  }
}))`
  position: relative;
  max-width: 100%;
  transition: ${props => props.neat ? 'transform 1s ease-in-out' : 'transform 18s ease-in-out'};
`;

function PostHolderFloat( { children, neat, greyscale, hovered } ) {
  const imageHolder = useRef( null );
  const [ transform, setTransform ] = useState( null );

  const calcTransform = () => {
    let image = imageHolder.current.getBoundingClientRect(),
      vw = window.innerWidth,
      imageW = image.width,
      margin = (vw - imageW) / 2,
      overflow = 0.5,
      maxX = margin + imageW * overflow,
      x = randomInteger( -maxX, maxX ),
      imageH = image.height,
      yMax = imageH,
      y = randomInteger( -yMax, yMax );

    return [x,y];
  };

  useEffect(() => {
    if(hovered && !neat) {
      // If hovered get where the image currently is, set that as transform values (ie. pause the transition)
      let computedStyle = window.getComputedStyle(imageHolder.current);
      const matrixValues = computedStyle.getPropertyValue('transform').match(/matrix.*\((.+)\)/)[1].split(', ');
      setTransform( [matrixValues[4],matrixValues[5]] );
    } else {
      setTransform( calcTransform() );

      let timerId = setTimeout(function pickSpot() {
        setTransform( calcTransform() );
        timerId = setTimeout(pickSpot, 18000);
      }, 18000);

      return () => clearTimeout(timerId);
    }

  }, [hovered, neat]);

  return (
    <Holder greyscale={greyscale}>
      <ImageHolder
        ref={imageHolder}
        neat={neat}
        transform={transform}>
        {children}
      </ImageHolder>
    </Holder>
  )
}

PostHolderFloat.propTypes = {
  neat: PropTypes.bool.isRequired,
  greyscale: PropTypes.bool,
  hovered: PropTypes.bool.isRequired,
};

export default PostHolderFloat;
